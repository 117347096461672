import { Monitor, QuestionnaireType } from '../webapi';

export function getQuestionnaireTypes(monitor: Monitor, isIKC: boolean, isVOPractical: boolean): QuestionnaireType[] {
  if (isIKC) {
    return [QuestionnaireType.IkcPreSelectedReport];
  }
  if (isVOPractical) {
    return [QuestionnaireType.VoPracticalEducation];
  }
  switch (monitor) {
    case Monitor.BookStart:
      return [QuestionnaireType.BookStartLibraryEmployee, QuestionnaireType.BookStartPedagogicalEmployee, QuestionnaireType.BookStartConsultant];
    case Monitor.Po:
    case Monitor.PoSpecial:
      return [QuestionnaireType.PoStudent, QuestionnaireType.PoTeacher, QuestionnaireType.PoConsultant];
    case Monitor.Vmbo:
      return [QuestionnaireType.VoStudent, QuestionnaireType.VoTeacher, QuestionnaireType.VoConsultant];
    case Monitor.Mbo:
      return [QuestionnaireType.MboStudent, QuestionnaireType.MboTeacher, QuestionnaireType.MboConsultant];
    case Monitor.Pabo:
      return [QuestionnaireType.PaboStudent, QuestionnaireType.PaboTeacher, QuestionnaireType.PaboConsultant];
  }
}
export function getMonitorType(questionnaireType: QuestionnaireType): Monitor {
  switch (questionnaireType) {
    case QuestionnaireType.BookStartLibraryEmployee:
    case QuestionnaireType.BookStartPedagogicalEmployee:
    case QuestionnaireType.BookStartConsultant:
    case QuestionnaireType.IkcPedagogicalEmployee:
    case QuestionnaireType.IkcPreSelectedReport:
      return Monitor.BookStart;
    case QuestionnaireType.PoStudent:
    case QuestionnaireType.PoTeacher:
    case QuestionnaireType.PoConsultant:
      return Monitor.Po;
    case QuestionnaireType.VoStudent:
    case QuestionnaireType.VoTeacher:
    case QuestionnaireType.VoConsultant:
    case QuestionnaireType.VoPracticalEducation:
      return Monitor.Vmbo;
    case QuestionnaireType.MboStudent:
    case QuestionnaireType.MboTeacher:
    case QuestionnaireType.MboConsultant:
      return Monitor.Mbo;
    case QuestionnaireType.PaboStudent:
    case QuestionnaireType.PaboTeacher:
    case QuestionnaireType.PaboConsultant:
      return Monitor.Pabo;
    default:
      throw new Error(`Questionnaire type not found ${questionnaireType}`);
  }
}

export enum ExtraReportType {
  ikcReport = 1,
  voPractical = 2,
}
