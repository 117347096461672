import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { getQuestionnaireTypes } from '../../core/translations/get-questionnaire-types';
import { QuestionnaireTypeNl } from '../../core/translations/questionnaire-type-nl';
import {
  Monitor,
  QuestionnaireType,
  ReportClient,
  ReportGroupDefinition,
  ReportMetaData,
  ReportQuestionModel,
  SpssVariableType,
} from '../../core/webapi';
import { CaseSelection } from './edit-report-setting/edit-report-setting.component';

export interface IQuestionDisplay {
  key: IReportSettingKey;
  text: string;
}

export interface IMonitorReportQuestions {
  questionnaire: string;
  reportQuestions: IQuestionDisplay[];
}

export interface IReportSettingKey {
  questionnaireType: QuestionnaireType;
  variable: string;
}

export interface IReportSetting {
  key: IReportSettingKey;
  vergelijkingen: string[]; // Graag niet vertalen!!
  onlyCountryFilter: boolean;
  caseSelection: CaseSelection | null;
  title?: string;
  isIKC: boolean;
}

@Injectable({ providedIn: 'root' })
export class ReportSettingsService {
  readonly suffixGrouped = '_Gegroepeerd';
  reportQuestions: Record<number, ReportQuestionModel[]> = {};
  reportGroups: Record<number, ReportGroupDefinition[]> = {};
  metaDatas: Record<number, ReportMetaData> = {};
  currentMonitor!: Monitor;
  constructor(private reportClient: ReportClient) {}

  async changeMonitorType(monitor: Monitor, year: number, isIKC: boolean, isVOPractical: boolean): Promise<void> {
    if (this.currentMonitor !== monitor) {
      this.reportQuestions = {};
      for (const qt of getQuestionnaireTypes(monitor, isIKC, isVOPractical)) {
        const reportQuestions = await firstValueFrom(this.reportClient.getClosedQuestionsForYear(qt, year));
        const metaData = await firstValueFrom(this.reportClient.getReportMetaDataForYear(qt, year));
        this.metaDatas[qt] = this.addGroupedQuestionToMetaData(metaData);
        this.reportQuestions[qt] = this.addGroupedQuestions(reportQuestions, metaData);
        this.reportGroups[qt] = await firstValueFrom(this.reportClient.getReportGroupDefinitionsForYear(qt, year));
      }
    }
  }

  getMonitorReportQuestions(monitor: Monitor, isIKC: boolean, isVOPractical: boolean): IMonitorReportQuestions[] {
    const monitorReportQuestions: IMonitorReportQuestions[] = [];
    for (const qt of getQuestionnaireTypes(monitor, isIKC, isVOPractical)) {
      const questionnaire = QuestionnaireTypeNl[qt];

      const reportQuestions = this.reportQuestions[qt];
      monitorReportQuestions.push({
        questionnaire,
        reportQuestions: reportQuestions
          .filter((rq) => rq.text !== '')
          .map<IQuestionDisplay>((rq) => ({
            key: { questionnaireType: qt, variable: rq.variable },
            text: rq.text,
          })),
      });
    }
    return monitorReportQuestions;
  }

  getDisplayGroups(questionnaireType: QuestionnaireType): IQuestionDisplay[] {
    const groupDisplays: IQuestionDisplay[] = [];
    const reportGroups = this.reportGroups[questionnaireType];

    groupDisplays.push(
      ...reportGroups.map<IQuestionDisplay>((rg) => ({
        key: { questionnaireType: questionnaireType, variable: rg.variable },
        text: rg.reportText,
      })),
    );

    return groupDisplays;
  }

  getQuestionDisplay(key: IReportSettingKey): IQuestionDisplay {
    const reportQuestions = this.reportQuestions[key.questionnaireType];
    const reportQuestion = reportQuestions.find((rq) => rq.variable === key.variable);
    const text = reportQuestion ? reportQuestion.text : key.variable;
    return { key, text };
  }

  getGroupDisplay(questionnaireType: QuestionnaireType, variable: string): string {
    const reportGroups = this.reportGroups[questionnaireType];
    const reportGroup = reportGroups.find((rq) => rq.variable === variable);
    return reportGroup ? reportGroup.reportText : variable;
  }

  private addGroupedQuestionToMetaData(metaData: ReportMetaData): ReportMetaData {
    let index = 100000;
    metaData.reportOutputDefinitions.forEach((od) => {
      if (od.groupedByQuestions && od.groupedByQuestions.length > 0) {
        metaData.reportVariables.push({
          isMultiResponse: false,
          variableId: index++,
          variableName: od.variable,
          decimals: od.decimalPlaces,
          variableDescription: od.label ?? od.variable,
          variableType: SpssVariableType.Number,
          remark: od.remark,
          categories: [],
        });
      }
      if (od.label) {
        const reportVariable = metaData.reportVariables.find((rv) => rv.variableName === od.variable);
        if (reportVariable) {
          reportVariable.variableDescription = od.label;
        }
      }
    });

    return metaData;
  }

  private addGroupedQuestions(questionModel: ReportQuestionModel[], metaData: ReportMetaData): ReportQuestionModel[] {
    metaData.reportVariables
      .filter((v) => v.variableName.endsWith(this.suffixGrouped))
      .forEach((rv) => {
        questionModel.push({
          variable: rv.variableName,
          text: rv.variableDescription,
        });
      });

    return questionModel;
  }
}
