import { CdkDrag, CdkDragDrop, CdkDragHandle, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgFor, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatCard, MatCardContent } from '@angular/material/card';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDivider } from '@angular/material/divider';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltip } from '@angular/material/tooltip';
import { ActivatedRoute } from '@angular/router';
import { SettingClient } from '@shared/data-access/common';
import { DialogConfirmComponent } from '@shared/ui/dialog';
import { firstValueFrom } from 'rxjs';
import { ExtraReportType, getQuestionnaireTypes } from '../../core/translations/get-questionnaire-types';
import { MonitorNl } from '../../core/translations/mdbos-monitor-nl';
import { QuestionnaireTypeNl } from '../../core/translations/questionnaire-type-nl';
import { Monitor, QuestionnaireType, ReportClient, ReportGroupClient, ReportMetaData } from '../../core/webapi';
import { ReportFilterService } from '../../reports/report/report-filter.service';
import { ISelectedQuestion } from '../../shared/report-types';
import { EditReportSettingTitleComponent } from './edit-report-setting-title/edit-report-setting-title.component';
import { EditReportSettingComponent, EditReportSettings } from './edit-report-setting/edit-report-setting.component';
import { IQuestionDisplay, IReportSetting, ReportSettingsService } from './report-settings.service';

export enum ReportType {
  Locatie = 1,
  Gemeente = 2,
  Bibliotheek = 3,
  Chamber = 4,
}

export const reportTypeNL: Record<ReportType, string> = {
  [ReportType.Locatie]: 'Locatie',
  [ReportType.Gemeente]: 'Gemeente',
  [ReportType.Bibliotheek]: 'Bibliotheek',
  [ReportType.Chamber]: 'Chamber',
};

@Component({
  selector: 'pma-report-settings',
  templateUrl: './report-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    NgIf,
    MatCard,
    MatCardContent,
    MatFormField,
    MatLabel,
    MatSelect,
    FormsModule,
    NgFor,
    MatOption,
    EditReportSettingComponent,
    CdkDropList,
    CdkDrag,
    MatDivider,
    MatIconButton,
    MatTooltip,
    CdkDragHandle,
    MatIcon,
    EditReportSettingTitleComponent,
    MatChipListbox,
    MatChipOption,
  ],
})
export class ReportSettingsComponent implements OnInit {
  readonly caseSelections: ISelectedQuestion[][] = [];
  readonly reportMetaDatas: Record<number, ReportMetaData> = {};
  isIKC;
  isVOPractical;
  measurementYears: number[] = [];
  measurementYear: number = new Date().getFullYear() - 1;
  monitor: Monitor | 0 | -1 = 0;
  reportType: ReportType | 0 = 0;
  initialReportSettings: IReportSetting[] = [];
  reportSettings: IReportSetting[] = [];
  data?: EditReportSettings;

  constructor(
    private route: ActivatedRoute,
    private reportSettingsService: ReportSettingsService,
    private settingsClient: SettingClient,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    public filterService: ReportFilterService,
    private matSnackBar: MatSnackBar,
    private reportClient: ReportClient,
    private reportGroupClient: ReportGroupClient,
    private destroyRef: DestroyRef,
  ) {
    this.isIKC = this.route.snapshot.paramMap.get('isSpecialReport') === ExtraReportType.ikcReport.toString();
    this.isVOPractical = this.route.snapshot.paramMap.get('isSpecialReport') === ExtraReportType.voPractical.toString();
  }

  ngOnInit() {
    this.route.params.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.reload());
  }

  async onYearChanged() {
    this.monitor = -1;
    await this.reload();
    this.cdr.markForCheck();
  }

  getTitle = (x: Monitor) => (this.isIKC ? 'IKC' : MonitorNl[x]);
  getReportType = (x: ReportType) => reportTypeNL[x];
  getQuestionnaireName = (x: QuestionnaireType) => QuestionnaireTypeNl[x];

  async onDelete(index: number) {
    const data = {
      message: 'Weet u zeker dat u deze voorselectie wilt verwijderen?',
      title: 'Verwijder voorselectie',
      okButton: 'Ja, ik weet het zeker',
    };
    const isOk = await DialogConfirmComponent.open(this.dialog, { data });
    if (isOk) {
      this.reportSettings.splice(index, 1);
      await this.save();
    }
  }

  async addReportSetting(reportSettings: IReportSetting) {
    this.reportSettings.push(reportSettings);
    await this.save();
  }

  async save() {
    // Reorder before saving
    await firstValueFrom(
      this.settingsClient.setSetting(
        this.filterService.getPreSelectDefinitionSettingsKey(
          this.measurementYear,
          this.monitor as Monitor,
          this.reportType as ReportType,
          this.isIKC ? QuestionnaireType.IkcPreSelectedReport : this.isVOPractical ? QuestionnaireType.VoPracticalEducation : null,
        ),
        JSON.stringify(this.reportSettings),
      ),
    );
    this.matSnackBar.open('De wijzigingen zijn opgeslagen.');
    await this.refresh();
  }

  async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.reportSettings, event.previousIndex, event.currentIndex);
    await this.save();
  }

  getQuestionDisplay(reportSetting: IReportSetting): IQuestionDisplay {
    return this.reportSettingsService.getQuestionDisplay(reportSetting.key);
  }

  getGroupReportText(variable: string, index: number, onlyCountryFilter: boolean): string {
    if (onlyCountryFilter && variable === 'Landelijk') {
      return 'Alleen landelijk';
    }
    return (
      this.reportSettingsService.reportGroups[this.reportSettings[index].key.questionnaireType].find((x) => x.variable === variable)?.reportText ?? ''
    );
  }

  getCaseSelection(reportSetting: IReportSetting): string {
    if (!reportSetting.caseSelection) {
      return '-';
    }
    if (reportSetting.caseSelection.answer) {
      return reportSetting.caseSelection.answer;
    }
    const index = getQuestionnaireTypes(this.monitor as Monitor, this.isIKC, this.isVOPractical).indexOf(reportSetting.key.questionnaireType);
    const question = this.caseSelections[index].find((x) => x.categories.find((c) => c.value === reportSetting.caseSelection!.value))!;
    return question.categories.find((c) => c.value === reportSetting.caseSelection!.value)!.description!;
  }

  private async getCaseSelections(monitor: Monitor) {
    const questionnaireTypes = getQuestionnaireTypes(monitor, this.isIKC, this.isVOPractical);

    for (let index = 0; index < questionnaireTypes.length; index++) {
      const reportMetaData = this.reportMetaDatas[questionnaireTypes[index]];
      const caseFilterVariableIds = reportMetaData.reportCaseFilters.map(
        (x) => reportMetaData.reportVariables.find((y) => y.variableName === x.variable)!.variableId,
      );
      const caseOptions = await firstValueFrom(
        this.reportClient.getCaseFilterForYear(questionnaireTypes[index], this.measurementYear, {
          caseFilterVariableIds: caseFilterVariableIds,
          filters: [],
        }),
      );
      this.caseSelections[index] = Object.entries(caseOptions).map(([variableId, categories]) => {
        const variable = reportMetaData.reportVariables.find((x) => x.variableId === +variableId)!;
        const caseFilterDefinition = reportMetaData.reportCaseFilters.find((x) => x.variable === variable.variableName)!;
        const question = {
          selectedGroups: [],
          variableId: +variableId,
          variableName: variable.variableName,
          variableType: variable.variableType,
          decimals: 0,
          isMultiResponse: false,
          variableDescription: caseFilterDefinition.reportText,
          categories: [],
          remark: variable.remark,
        } as ISelectedQuestion;
        Object.entries(categories).forEach(([categoryId, values]) => {
          const categories = values.sort().map((v) => ({
            categoryId: +categoryId,
            description: v,
            value: v,
          }));
          question.categories.push(...categories);
        });
        return question;
      });
    }
  }

  private async reload() {
    this.reportSettings = [];
    const prevMonitor = this.monitor;
    const prevReportType = this.reportType;
    const prevIsVoPractical = this.isVOPractical;
    const prevIsIkc = this.isIKC;
    this.monitor = +(this.route.snapshot.paramMap.get('monitor') ?? 0);
    this.reportType = +(this.route.snapshot.paramMap.get('reportType') ?? 0);
    this.isVOPractical = +(this.route.snapshot.paramMap.get('isSpecialReport') ?? 0) === ExtraReportType.voPractical;
    this.isIKC = +(this.route.snapshot.paramMap.get('isSpecialReport') ?? 0) === ExtraReportType.ikcReport;

    if (prevMonitor !== this.monitor || prevReportType !== this.reportType || prevIsIkc !== this.isIKC || prevIsVoPractical !== this.isVOPractical) {
      this.measurementYears = await firstValueFrom(this.reportGroupClient.getMeasurementYears(this.monitor));
      if (this.isIKC) {
        this.measurementYears = this.measurementYears.filter((my) => my > 2021);
      }
      if (this.monitor > 0) {
        this.measurementYear = this.measurementYears[this.measurementYears.length - 1];
      }

      await this.reportSettingsService.changeMonitorType(this.monitor, this.measurementYear, this.isIKC, this.isVOPractical);
      const questionnaireTypes = getQuestionnaireTypes(this.monitor, this.isIKC, this.isVOPractical);

      for (const questionnaireType of questionnaireTypes) {
        this.reportMetaDatas[questionnaireType] = await firstValueFrom(
          this.reportClient.getReportMetaDataForYear(questionnaireType, this.measurementYear),
        );
      }

      await this.getCaseSelections(this.monitor);
    }

    this.data = {
      monitor: this.monitor,
      reportSettings: this.reportSettings,
      caseSelections: this.caseSelections,
      isIKC: this.isIKC,
      isVOPractical: this.isVOPractical,
    };

    await this.refresh();
  }

  private async refresh() {
    const specialReport = this.isIKC ? QuestionnaireType.IkcPreSelectedReport : this.isVOPractical ? QuestionnaireType.VoPracticalEducation : null;
    const filterService = this.filterService;
    const key = filterService.getPreSelectDefinitionSettingsKey(
      this.measurementYear,
      this.monitor as Monitor,
      this.reportType as ReportType,
      specialReport,
    );
    const stringSettings = await firstValueFrom(this.settingsClient.getSetting(key));
    this.initialReportSettings = this.parseSettings(stringSettings);
    this.reportSettings = this.parseSettings(stringSettings);

    this.cdr.markForCheck();
  }

  private parseSettings(stringSettings: string | null) {
    return stringSettings === null ? [] : JSON.parse(stringSettings);
  }
}
